() => {
  function setBodyFontSize() {
    let windowWidth = window.innerWidth;
    if (windowWidth >= 1440 || windowWidth < 992) {
      document.body.style.removeProperty('font-size');
    } else {
      document.body.style.fontSize = `${windowWidth / 100 / 16}rem`;
    }
  }

  setBodyFontSize();

  window.addEventListener('resize', setBodyFontSize);
};
